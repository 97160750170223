import React from "react";
// import global from "../images/Contactus.jpg";
import global from "../images/contactnew.jpg";
import ph1 from "../images/ph1.png";
import ph2 from "../images/ph2.png";
import "../Styles/contact.css";
import "../Styles/card.css";
import { Button, Col, Row, Container } from "react-bootstrap";
import RubberBand from "react-reveal/RubberBand";
import { useForm } from "react-hook-form";
import axios from "axios";

export default function Contact() {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data) => {
    console.log(data);
    const res = await axios
      .post("http://52.66.7.123:3001/user/register", {
        name: data.name,
        emailId: data.email,
        phoneNo: data.mobile,
      })
      .then((res) => {
        console.log(res.data);
        alert("We will reach you soon...");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div id="contact">
      <br />
      <br />
      <RubberBand>
        <div className="contact">
        <Row style={{ paddingLeft: "4%", color: "black", paddingRight: "4%" }}>
            <Col className="p-3" xs={12} md={6}>
              <div style={{ padding: "6%", width: "100%", height: "100%", boxShadow: "5px 5px 15px rgba(124,119,119,0.5)", borderRadius:"20px" }}>
                <Row><p style={{ textAlign: "left", fontSize: "30px" }}><b>Software for Startups to Fortune Companies</b></p></Row>
                <Row><p style={{ textAlign: "left" }}>To make your dream come true, you should have the best-in-class team with exceptionally skilled to accomplish it.
                  We have an awesome team of exceptionally skilled people in all technologies across devices and platforms.</p>
                </Row>
              </div>
            </Col>
            <Col className="p-3" xs={12} md={6}>
              <div style={{ padding: "6%", width: "100%", height: "100%", boxShadow: "5px 5px 15px rgba(124,119,119,0.5)", borderRadius:"20px"  }}>
                <Row><p style={{ textAlign: "left", fontSize: "30px" }}><b>'Travail-Team' For Your Vision</b></p></Row>
                <Row><p style={{ textAlign: "left" }}>We have worked with small startups who are just bootstrapping to Fortune companies with revenues of over billion. We
                  have the agility needed for a start-up and the capacity needed for a corporation and the technical expertise needed for both.</p>
                </Row>
              </div>
            </Col>
          </Row>
          <br /><br /><br />
          <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
          <div style={{ display:"flex",flexDirection:"column", alignItems: "center", backgroundColor: "#F5F5F5" }}>
              <p style={{ color: "black", fontSize: "30px" }}><b>Let's transform your business for a change that matters</b></p>
            <br/>
                <Button style={{ backgroundColor: "black", border: "none", borderRadius: "0",padding: "center" }}>Get a Proposal</Button>
            <br />
          </div>
          </div>
        </div>
      </RubberBand>
    </div>
  );
}
