import React from 'react';

import '../Styles/footer.css'
import { Col, Row } from 'react-bootstrap';
import copy from '../images/copyy.png'
class Footer extends React.Component {


  render() {
    return (
      <div>
        <footer className="footer">

          <Row style={{ margin: '0px' }}>
            {/* <Col md='3' lg='3' xs='12' sm="6">
              <img src={logo} style={{ alignSelf: 'center', width: '75%', height: '75%', marginTop: '5%' }} />
            </Col> */}
            <Col md='2' lg='2' xs='12' sm="6">
              <br /><br />
              <p style={{ color: '#202b5d', fontSize: '20px' }}><b>Comparison</b></p>
              <ul>
                <li><a style={{ color: '#202b5d' }} href="#why">Why Choose Us</a></li>
                <li><a style={{ color: '#202b5d' }} href="#features">Our Services</a></li>
                <li><a style={{ color: '#202b5d' }} href="#contact">Contact</a></li>
                <li><a style={{ color: '#202b5d' }} href="#ourproducts"> Our Products</a></li>
              </ul>
            </Col>

            <Col md='2' lg='2' xs='12' sm="6">
              <br /><br />
              <p style={{ color: '#202b5d', fontSize: '20px' }}><b>Services</b></p>
              <ul>
                <li style={{ color: '#202b5d' }}>Product Development</li>
                <li style={{ color: '#202b5d' }}>Market Your Business</li>
                <li style={{ color: '#202b5d' }}>Manage Everything</li>
                <li style={{ color: '#202b5d' }}>Cloud Storage</li>
              </ul>
            </Col>

            <Col md='2' lg='2' xs='12' sm="6">
              <br /><br />
              <p style={{ color: '#202b5d', fontSize: '20px' }}><b>Products</b></p>
              <ul>
                <li style={{ color: '#202b5d' }}> Digital Signage Solution</li>
                <li style={{ color: '#202b5d' }}> Employee Management Application</li>
                <li style={{ color: '#202b5d' }}> Constrction Application</li>
                <li style={{ color: '#202b5d' }}> Sass E-Commerce Solutions</li>
              </ul>
            </Col>



            <Col md='2' lg='2' xs='12' sm="6">
              <br /><br />
              <p style={{ color: '#202b5d', fontSize: '20px' }}><b>Products</b></p>
              <ul>
                <li style={{ color: '#202b5d' }}> AI edtech products</li>
                <li style={{ color: '#202b5d' }}> Automobile Application</li>
                <li style={{ color: '#202b5d' }}> Manufacture Industry System</li>
                <li style={{ color: '#202b5d' }}> Lifesciences Sass Products</li>
              </ul>
            </Col>


            <Col md='2' lg='2' xs='12' sm="6">
              <br />
              <br /><p style={{ color: '#202b5d', fontSize: '20px' }}><b>Engineering</b></p>
              <ul>
                <li style={{ color: '#202b5d' }}>Product Engineering</li>
                <li style={{ color: '#202b5d' }}>Data Analytics</li>
                <li style={{ color: '#202b5d' }}>AI / ML </li>
                <li style={{ color: '#202b5d' }}>Mobile Apps</li>
              </ul>
              <p style={{ color: '#202b5d', fontSize: '20px' }}><b>Connect with Us </b></p>
              {/* <section id='lab_social_icon_footer'> */}
              <Row style={{ margin: '0px' }} className='foot'>
                <a href="https://www.instagram.com/travailtechnologies/" target='_blank'><i id="social-fb" class="fa fa-instagram fa-2x social"></i></a>
                <a href="https://twitter.com/TravailTechnol1" target='_blank'><i id="social-tw" class="fa fa-twitter-square fa-3x social"></i></a>
                <a href="https://www.youtube.com/channel/UC2Q1IcQAplOpLwDLRMqeN0Q/about/" target='_blank'><i id="social-gp" class="fa fa-youtube-play fa-3x social"></i></a>
                <a href="https://www.linkedin.com/company/travail-technologies/" target='_blank'><i id="social-em" class="fa fa-linkedin-square fa-3x social"></i></a>
              </Row>
              {/* </section> */}
            </Col>
          </Row>
          <p style={{ color: '#202b5d', fontSize: '20px', textAlign: "center" }}>Copyright  <img src={copy} width='32px' height='32px' /> Travail Technologies. All rights reserved.</p>
        </footer>
      </div>
    )
  }
}

export default Footer;