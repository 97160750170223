import React, { Component } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import "../Styles/navbar.css";
import logo from "../images/logo-travail.png";
import Scrollchor from "react-scrollchor";

// import { Link } from '@material-ui/core';
class Navbar extends Component {
  state = {
    clicked: false,
  };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  render() {
    return (
      <div className="NavbarItems navbar-fixed-top">
        <img
          src={logo}
          alt="logo"
          height="50px"
          width="60px"
          className="navbar-logo"
        />
        <div className="menu-icon" onClick={this.handleClick}>
          <i className="bars">
            {this.state.clicked ? (
              <CloseIcon fontSize="large" />
            ) : (
              <MenuIcon fontSize="large" />
            )}
          </i>
        </div>

        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          <li>
            <a>
              <Scrollchor to="home" className="nav-links">
                Home
              </Scrollchor>
            </a>
          </li>
          <li>
            <a href="/technologies" className="nav-links">
              Technologies
            </a>
          </li>

          {/* {/* <li><a href='/Ourteam' className="nav-links">Our Team</a></li> */}
          <li>
            <a href="/Products" className="nav-links">
              Products
            </a>
          </li>

          <li>
            <a href="/service" className="nav-links">
              Services
            </a>
          </li>
          <li>
            <a href="/RD" className="nav-links">
              R&D
            </a>
          </li>
          {/* <li><a href='/academy' className="nav-links">Academy</a></li> */}

          <li>
            <a href="/Career" className="nav-links">
              Career
            </a>
          </li>

          <li>
            <a>
              <Scrollchor to="contact" className="nav-links">
                Contact Us
              </Scrollchor>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
export default Navbar;
